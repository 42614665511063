.welcomePage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.oval-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
  z-index: -1;
}
.oval-wrapper svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.welcome-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-start {
  margin: 24px;
  padding: 16px 24px;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  background: #03d17c;
  border: 1px solid #41efcd;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button-start:hover,
.button-start:active,
.button-start:focus {
  color: #41efcd;
  background: white;
  text-decoration: none;
}

.welcome-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 48%;
}

.headline {
  display: flex;
  justify-content: space-between;
  width: 150%;
}

.product-demo-image {
  width: 45%;
  box-shadow: 1px 1px 20px 0px #eee;
  border: 10px solid white;
}

.welcome-content .title {
  margin: 0 0 4rem 0;
  line-height: 58px;
  font-size: 60px;
  text-align: left;
}

.welcome-content .title span:nth-child(3) {
  color: #f199c0;
}

.feature-box {
  position: relative;
  padding: 2rem 16px;
  width: 100%;
  text-align: center;
}
.feature-box-title {
  margin: 0;
  margin-bottom: 14px;
  font-size: 22px;
}
.feature-box-description {
  margin: 0;
  font-size: 18px;
}

@media (max-width: 1023px) {
  .headline {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .product-demo-image {
    width: 80%;
    border: 5px solid white;
  }
}

@media (max-width: 680px) {
  .welcome-content {
    width: 82%;
  }
  .welcome-content .title {
    margin: 0 0 3rem 0;
    line-height: 50px;
    font-size: 52px;
  }
  .feature-box {
    padding: 1.5rem 8px;
  }
  .feature-box-title {
    margin-bottom: 8px;
    font-size: 20px;
  }
  .feature-box-description {
    font-size: 16px;
  }
  .product-demo-image {
    width: 60%;
  }
}
@media (max-width: 510px) {
  .product-demo-image {
    width: 130%;
    border: none;
  }
}
