body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181818;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.red {
  color: #ff7777;
}

.orange {
  color: #eec22f;
}

:root {
  --toastify-color-success: #41efcd !important;
  --toastify-text-color-success: #000 !important;

  --toastify-color-error: #ff7777 !important;
  --toastify-text-color-error: #000 !important;
}
